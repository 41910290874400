import * as React from "react"
import '../../styles/title.css'
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const FullTitleComponent = ({ article }) => {
    const backgroundImageStyle =
    {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${article.headerImage})`,
    }
    const { t } = useTranslation();
    return (
        <section className="bg-cover py-32 lg:py-12" style={backgroundImageStyle}>
            <div className="flex flex-col gap-3 px-5 items-center " >
                <div className="self-start flex gap-3 container mx-auto items-center flex-wrap">
                    <div className="flex gap-3  items-center">
                        <Link className="underline" to={`/`}>{t("menu_home")}</Link>
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </div>
                    <div className="flex gap-3  items-center">
                        <Link className="underline" to={`/${article.typeArticle}`}>{t("menu_" + article.typeArticle)}</Link>
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </div>

                    <span>{article.title}</span>
                </div>


                <h1 className="text-5xl font-black m-0 text-center uppercase lg:text-4xl">{article.title}</h1>
            </div>

        </section>
    )
}

export default FullTitleComponent;