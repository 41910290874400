import * as React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import FullTitleComponent from "../shared/full-title"
import ArticleSidebar from "./more-article-sidebar"
import Layout from "../layout/layout"
import parse from "../../utils/html-parser.options"
import videojs from 'video.js';
import "../../styles/article.css"
import "../../styles/rte.css"
import SEOComponent from "../seo";
const ArticlePage = ({ data }) => {
    const article = data.article;
    const siteContent = data.siteContent
    React.useEffect(() => {

        // get videojs from document
        const video = document.getElementsByClassName("video-js")[0];
        if(video == null) {
            console.log("no video found");
            return;
        }
        var options = {
            controlBar: {
                children: [
                  'PlayToggle',
                  'ChaptersButton',
                  //'VolumeMenuButton',
                  'ProgressControl',
                  //'CurrentTimeDisplay',
                  //'TimeDivider',
                  //'LiveDisplay',
                  //'RemainingTimeDisplay',
                  
                  
                  'CaptionsButton',
                  'volumePanel',
                  'DescriptionsButton',
                  //'SubtitlesButton',
                  'qualitySelector',
                  'fullscreenToggle',
                ],
            },
        };
        // init videojs
        videojs(video, options, function () {
            videojs.log('player is ready');
        });
      }, [article]);
    return (
        <main>
            <Layout siteContent={siteContent}>
                <SEOComponent title={article.title} description={article.shortDescription} article />
                <FullTitleComponent article={article} ></FullTitleComponent>
                <section className="section mt-10 px-5">
                    <div className="flex gap-20 lg:flex-col">
                        <div className="whitespace-normal rte">
                            {parse(article.html)}
                        </div>
                        <div className="mt-40 ">
                            <div className="w-80 lg:w-full">
                                <ArticleSidebar relatedArticles={article.featuredArticles} typeArticle={article.typeArticle} currentArticle={article}></ArticleSidebar>

                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </main>
    )
}
export default ArticlePage;
