import ArticlePage from "../../components/article/article";
import { graphql } from "gatsby"
export default ArticlePage;

export const query = graphql`
  query($slug: String ,$language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteContent (language: {eq: $language}) {
      editionYear
      programYear
      language
      cookieURL
        personalDataURL
        mediaKit
        banner
    }
    article(slug: { eq: $slug },language: { eq: $language }) {
        title
      slug
      shortDescription
      typeArticle
      categories
      tags
      html
      thumbnailImage
      headerImage
      featuredArticles {
        title
        shortDescription
        thumbnailImage
        slug
      }
      language
      datePublished
      newsHeadline
    }
  }
`