import * as React from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

const SocialComponent = ({ article, className, fontSize, facebookURL, instagramURL, twitterURL, linkedInURL }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const isBrowser = () => typeof window !== "undefined"
  const articleURL = article && isBrowser() ? `${window.location.protocol}//${window.location.host}${language === 'fr' ? "" : "/" + language}/article/${article.slug}` : ""
  return (<div className={className}>
    <a rel='noreferrer' href={facebookURL ?? `https://www.facebook.com/sharer/sharer.php?u=${articleURL}`} target="_blank" title={t("text_facebook")}>
      <FacebookIcon fontSize={fontSize} ></FacebookIcon>
    </a>
    {instagramURL &&
      <a rel='noreferrer' href={instagramURL} target="_blank" title={t("text_instagram")}>
        <InstagramIcon fontSize={fontSize}></InstagramIcon>
      </a>
    }

    <a rel='noreferrer' href={twitterURL ?? `https://twitter.com/intent/tweet?url=${articleURL}&text=${article.title}`} target="_blank" title={t("text_twitter")}>
      <TwitterIcon fontSize={fontSize}></TwitterIcon>
    </a>
    <a rel='noreferrer' href={linkedInURL ?? `https://www.linkedin.com/shareArticle?mini=true&url=${articleURL}&title=${article.title}&summary=${article.shortDescription}&source=strasbourg_eu`} target="_blank" title={t("text_linkedin")}>
      <LinkedInIcon fontSize={fontSize} ></LinkedInIcon>
    </a>

  </div>)
}

export default SocialComponent;