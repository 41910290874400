import * as React from "react"
import FooterComponent from "./footer"
import NavBar from "../navbar"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Tooltip } from 'react-tooltip'

export default function Layout({ children, siteContent }) {
  const { t } = useTranslation();

  const { allYearVocabulary } = useStaticQuery(query);
  const years = allYearVocabulary.nodes;
  const editionYear = siteContent.editionYear;

  const yearsWithoutCurrent = years.filter(el => el.year !== editionYear);

  const editionsByYear = yearsWithoutCurrent.map(year => ({
    url: `/edition/${year.year}`,
    name: t("menu_edition", { year: year.year })
  }));

  const programYear = siteContent.programYear;
  const navigation = [
    { url: "/", name: t("menu_home") },
    { url: "/commitment", name: t("menu_news") },
    { url: "/senr", name: t("senr", { year: programYear }) },
    { url: "/ressources", name: t("menu_ressources") },
    { url: "/contact", name: t("menu_contact") },
  ];
  const navigationWithoutContact = navigation.filter(el => el.url !== "/contact");
  const navigationWithoutPartners = navigation.filter(el => el.url !== "/partenaires");
  
  return (

    <div>
      <div className="banner"> </div>
      <NavBar navigation={navigation} navigationWithoutContact={navigationWithoutContact} banner={siteContent.banner}/>
      <div>
        {children}
      </div>
      <FooterComponent navigation={navigationWithoutPartners} cookieURL={siteContent.cookieURL} personalDataURL={siteContent.personalDataURL} mediaKit={siteContent.mediaKit}></FooterComponent>
      <Tooltip id="tooltip-main" style={{ maxWidth: '400px', wordWrap: 'break-word', zIndex : '99999' }} />
    </div>

  )
}

const query = graphql`
  query Layout {
    allYearVocabulary(sort: {order: DESC, fields: year}) {
      nodes {
        year
      }
    }
  }
`