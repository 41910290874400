import * as React from "react"
import LinkButton from "../elements/LinkButton";
import {  Link, useTranslation } from "gatsby-plugin-react-i18next";
import SocialComponent from "../socials";

const ArticleSidebar = ({ relatedArticles, typeArticle, currentArticle }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col  gap-10 lg:flex-row lg:flex-wrap">
                {relatedArticles.map((article, i) => <Link key={i} to={"/article/"+article.slug} className="article-side">
                    <img loading="lazy" src={article.thumbnailImage} alt={"Image of " + article.title}></img>
                    <span className="subtitle"><strong>{article.title}</strong></span>
                </Link>)}
            </div>

            <LinkButton to={`/${typeArticle === "news" ? 'commitment' : typeArticle}`}>{t("other_articles", { article_type: t("menu_" + typeArticle).toLowerCase() })}</LinkButton>
            <div className="flex flex-col gap-5">
                <strong>{t("share_social")}</strong>
                <SocialComponent className="flex gap-6" fontSize="large" article={currentArticle}>
                </SocialComponent>
            </div>
        </div>
    )
}

export default ArticleSidebar;
